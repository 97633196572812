<template>
  <b-card
    no-body
    class="mb-0"
  >
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2 col-md-6 col-xs-12"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <h5>
          {{ title }}
        </h5>

        <!-- Nom -->
        <validation-provider
          #default="validationContext"
          name="Nom"
          rules="required"
        >
          <b-form-group
            label="Nom"
            label-for="nom"
          >
            <b-form-input
              id="nom"
              v-model="siteData.nom"
              autofocus
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              Le champ Nom est requis
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Site Type -->
        <validation-provider
          v-if="!isUpdate"
          #default="validationContext"
          name="Type"
          rules="required"
        >
          <b-form-group
            label="Type"
            label-for="siteType"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="siteData.siteType"
              :options="siteTypeOptions"
              :clearable="false"
              input-id="siteType"
              class="select-cursor"
            ><div slot="no-options">
              Aucune option
            </div></v-select>
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              Le champ Type est requis
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Code -->

        <b-form-group
          label="Code par défaut"
          label-for="code"
        >
          <b-form-input
            id="code"
            v-model="siteData.code"
            trim
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            {{ submitButtonText }}
          </b-button>
        </div>

      </b-form>
    </validation-observer>
  </b-card>

</template>

<script>
import {
  BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    submitButtonText: {
      type: String,
      required: true,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    siteFormData: {
      type: Object,
      default: () => ({
        nom: '',
        siteType: '',
        code: '',
      }),
    },
  },
  data() {
    return {
      siteTypeOptions: ['destination', 'provenance'],
      required,
      email,
    }
  },
  setup(props, { emit }) {
    const siteData = ref({ ...props.siteFormData })
    const resetSiteData = () => {
      siteData.value = { ...props.siteFormData }
    }

    const onSubmit = () => {
      emit('submitSiteForm', siteData.value)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetSiteData)

    return {
      siteData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
